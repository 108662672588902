export const BloodGlucoseColors = {
    beforeDawnColor: '#000000',

    preBreakfastColor: '#00cc00',
    postBreakfast1hColor: '#99cccc',
    postBreakfast2hColor: '#336699',

    preForenoonColor: '#0099cc',
    postForenoon1hColor: '#99ccff',
    postForenoon2hColor: '#006699',

    preLunchColor: '#ffcc00',
    postLunch1hColor: '#ffff99',
    postLunch2hColor: '#cccc66',

    preAfternoonColor: '#666600',
    postAfternoon1hColor: '#cccc33',
    postAfternoon2hColor: '#ffffcc',

    preSupperColor: '#9999cc',
    postSupper1hColor: '#ffcccc',
    postSupper2hColor: '#663366',

    preEveningColor: '#666666',
    postEvening1hColor: '#999999',
    postEvening2hColor: '#cccccc',

    bedtimeColor: '#003366',
}