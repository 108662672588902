<template>
  <div>
    <div
        class="layout-horizontal layout-middle"
        v-for="(timeNode, index) in bloodGlucoseTimeNodeList"
        :key="timeNode.time"
    >
      <time-line
          v-if="index === 0"
          :title="value.date"
          class="layout-self-stretch"
      ></time-line>
      <time-line
          v-else
          only-line
          class="layout-self-stretch"
      >
      </time-line>
      <div class="padding-left-large padding-vertical-large" style="width: 56px;">
        {{ timeNode.timeStr }}
      </div>
      <div class="padding-left-large" style="width: 100px;">
        {{ timeNode.value.typeStr }}
      </div>
      <div class="padding-left-large padding-vertical-large font-size-medium" style="width: 138px;">
        {{ timeNode.value.glucoseStr }} mmol/L
      </div>
      <div
          class="padding-left-large"
          :class="timeNode.value.isWarning ? 'font-color-danger' : 'font-color-success'"
      >
        {{ timeNode.value.levelStr }}
      </div>

    </div>


  </div>
</template>

<script>
import TimeLine from "@/pages/minitools/components/TimeLine";
import ExactNumber from "@/assets/javascript/exact-number";
import {BloodGlucoseLevel, BloodGlucoseUtils} from "@/assets/javascript/blood-glucose-utils";
import {TimeUtils} from "@/assets/javascript/time-utils";

export default {
  name: "BloodGlucoseListItem",
  components: {TimeLine},
  props: {
    value: Object,
  },
  data() {
    return {
      bloodGlucoseTimeNodeList: [],
    }
  },
  watch: {
    value: {
      handler: function (dateNode) {
        let timeNodeList = dateNode.value.bloodGlucoseList;
        let reversedTimeNodeList = new Array(timeNodeList.length);
        for (let n = 0; n < timeNodeList.length; n++) {
          let timeNode = timeNodeList[n];
          timeNode.timeStr = TimeUtils.format('HH:mm', dateNode.date + " " + timeNode.time);
          timeNode.value.glucoseStr = ExactNumber.stringify(timeNode.value.glucose);
          timeNode.value.typeStr = BloodGlucoseUtils.getBloodGlucoseTypeStr(timeNode.value.type);
          timeNode.value.levelStr = BloodGlucoseUtils.getBloodGlucoseLevelStr(timeNode.value.level);
          let isWarning = false;
          switch (timeNode.value.level) {
            case BloodGlucoseLevel.LOW:
            case BloodGlucoseLevel.BAD:
            case BloodGlucoseLevel.WORSE:
              isWarning = true;
              break;
          }
          timeNode.value.isWarning = isWarning;
          reversedTimeNodeList[timeNodeList.length - n - 1] = timeNode;
        }
        this.bloodGlucoseTimeNodeList = reversedTimeNodeList;
      },
      immediate: true,
    }
  }
}
</script>

<style scoped>

</style>