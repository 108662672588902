<template>
  <div class="page-mini-tool padding layout-horizontal font-align-left">
    <div class="card layout-inflexible" style="overflow: hidden;" v-if="false">
      <div class="padding-top-large padding-horizontal-large padding-bottom"
           style="background-color: #f091a6; color: white;">
        <user-info
            :user-id="userId"
            :organization-id="organizationId"
        ></user-info>
      </div>
      <blood-glucose-calendar
          :user-id="userId"
          :organization-id="organizationId"
          class="padding-horizontal padding-bottom-large"
      ></blood-glucose-calendar>
    </div>
    <div class="layout-flexible margin-left layout-vertical">
      <div class="font-size-large margin-bottom font-weight-bold">血糖管理</div>
      <div class="card">
        <div class="font-weight-bold padding-left-large padding-top"><i class="fas fa-chart-line"></i> 趋势</div>
        <blood-glucose-linear-chart
            :user-id="userId"
            :organization-id="organizationId"
        ></blood-glucose-linear-chart>
      </div>
      <div class="card layout-flexible layout-vertical margin-top" style="overflow: hidden">
        <div class="font-weight-bold layout-inflexible padding-horizontal-large padding-top-large padding-bottom"><i class="fas fa-list"></i> 列表</div>
        <blood-glucose-list
            :user-id="userId"
            :organization-id="organizationId"
            class="layout-flexible"
        ></blood-glucose-list>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import UserInfo from "@/pages/minitools/components/UserInfo";
import BloodGlucoseCalendar from "@/pages/minitools/blood_glucose/components/BloodGlucoseCalendar";
import BloodGlucoseLinearChart from "@/pages/minitools/blood_glucose/components/BloodGlucoseLinearChart";
import BloodGlucoseList from "@/pages/minitools/blood_glucose/components/BloodGlucoseList";

export default {
  name: "MiniToolBloodGlucose",
  components: {BloodGlucoseList, BloodGlucoseLinearChart, BloodGlucoseCalendar, UserInfo},
  mixins: [httpapi],
  data() {
    return {
      userId: null,
      organizationId: null,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.userId = query.userId;
    this.organizationId = query.organizationId;
  }
}
</script>

<style scoped>

.page-mini-tool {
  background-color: whitesmoke;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

</style>