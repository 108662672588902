/**
 * 血糖类型
 */
export const BloodGlucoseType = {

    /**
     * 随机血糖
     */
    RANDOM: 0,

    /**
     * 凌晨
     */
    BEFORE_DAWN: 1,

    /**
     * 睡前
     */
    BEDTIME: 2,


    /**
     * 早餐前
     */
    PRE_BREAKFAST: 10,

    /**
     * 早餐后1小时
     */
    POST_BREAKFAST_1H: 11,

    /**
     * 早餐后2小时
     */
    POST_BREAKFAST_2H: 12,

    /**
     * 早点（上午餐）前
     */
    PRE_FORENOON: 20,

    /**
     * 早点（上午餐）后1小时
     */
    POST_FORENOON_1H: 21,

    /**
     * 早点（上午餐）后2小时
     */
    POST_FORENOON_2H: 22,

    /**
     * 午饭前
     */
    PRE_LUNCH: 30,

    /**
     * 午饭后1小时
     */
    POST_LUNCH_1H: 31,

    /**
     * 午饭后2小时
     */
    POST_LUNCH_2H: 32,

    /**
     * 午点（下午餐）前
     */
    PRE_AFTERNOON: 40,

    /**
     * 午点（下午餐）后1小时
     */
    POST_AFTERNOON_1H: 41,

    /**
     * 五代你（下午餐）后2小时
     */
    POST_AFTERNOON_2H: 42,

    /**
     * 晚餐前
     */
    PRE_SUPPER: 50,

    /**
     * 晚餐后1小时
     */
    POST_SUPPER_1H: 51,

    /**
     * 晚餐后2小时
     */
    POST_SUPPER_2H: 52,

    /**
     * 晚点（宵夜）前
     */
    PRE_EVENING: 60,

    /**
     * 晚点（宵夜）后1小时
     */
    POST_EVENING_1H: 61,

    /**
     * 晚点（宵夜）后2小时
     */
    POST_EVENING_2H: 62,

}

/**
 * 血糖水平（不同血糖类型，阈值不同）
 */
export const BloodGlucoseLevel = {

    /**
     * 未知
     */
    UKNOWN: 0,

    /**
     * 偏低
     */
    LOW: 1,

    /**
     * 正常
     */
    NORMAL: 2,

    /**
     * 良好
     */
    FINE: 3,

    /**
     * 一般
     */
    ORDINARY: 4,

    /**
     * 不良
     */
    BAD: 5,

    /**
     * 非常不良
     */
    WORSE: 6

}

function getBloodGlucoseTypeStr(type) {
    switch (type) {
        case BloodGlucoseType.RANDOM:
            return "随机血糖";
        case BloodGlucoseType.BEFORE_DAWN:
            return "凌晨";
        case BloodGlucoseType.BEDTIME:
            return "睡前";
        case BloodGlucoseType.PRE_BREAKFAST:
            return "早餐前";
        case BloodGlucoseType.POST_BREAKFAST_1H:
            return "早餐后1小时";
        case BloodGlucoseType.POST_BREAKFAST_2H:
            return "早餐后2小时";
        case BloodGlucoseType.PRE_FORENOON:
            return "早点前";
        case BloodGlucoseType.POST_FORENOON_1H:
            return "早点后1小时";
        case BloodGlucoseType.POST_FORENOON_2H:
            return "早点后2小时";
        case BloodGlucoseType.PRE_LUNCH:
            return "午餐前";
        case BloodGlucoseType.POST_LUNCH_1H:
            return "午餐后1小时";
        case BloodGlucoseType.POST_LUNCH_2H:
            return "午餐后2小时";
        case BloodGlucoseType.PRE_AFTERNOON:
            return "午点前";
        case BloodGlucoseType.POST_AFTERNOON_1H:
            return "午点后1小时";
        case BloodGlucoseType.POST_AFTERNOON_2H:
            return "午点后2小时";
        case BloodGlucoseType.PRE_SUPPER:
            return "晚餐前";
        case BloodGlucoseType.POST_SUPPER_1H:
            return "晚餐后1小时";
        case BloodGlucoseType.POST_SUPPER_2H:
            return "晚餐后2小时";
        case BloodGlucoseType.PRE_EVENING:
            return "晚点前";
        case BloodGlucoseType.POST_EVENING_1H:
            return "晚点后1小时";
        case BloodGlucoseType.POST_EVENING_2H:
            return "晚点后2小时";
        default:
            return "";
    }

}

function getBloodGlucoseLevelStr(level) {
    switch (level) {
        case BloodGlucoseLevel.UKNOWN:
            return "";
        case BloodGlucoseLevel.LOW:
            return "偏低";
        case BloodGlucoseLevel.NORMAL:
            return "正常";
        case BloodGlucoseLevel.FINE:
            return "良好";
        case BloodGlucoseLevel.ORDINARY:
            return "一般";
        case BloodGlucoseLevel.BAD:
            return "不良";
        case BloodGlucoseLevel.WORSE:
            return "非常不良";
        default:
            return "";
    }
}

export const BloodGlucoseUtils = {
    getBloodGlucoseTypeStr,
    getBloodGlucoseLevelStr,
}
