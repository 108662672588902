<template>
  <ys-infinite-scroll-view
      @loadmore="loadBloodGlucoseDateRecordListBefore"
  >
    <blood-glucose-list-item
        v-for="dateNode in bloodGlucoseDateRecordList"
        :key="dateNode.date"
        :value="dateNode"
    ></blood-glucose-list-item>
    <div class="font-align-center font-color-placeholder font-size-small padding padding-large">已经滑动到底部</div>
  </ys-infinite-scroll-view>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import BloodGlucoseListItem from "@/pages/minitools/blood_glucose/components/BloodGlucoseListItem";

export default {
  name: "BloodGlucoseList",
  mixins: [httpapi],
  components: {BloodGlucoseListItem, YsInfiniteScrollView},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 血糖日记录列表
       */
      bloodGlucoseDateRecordList: [],

    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.loadBloodGlucoseDateRecordListBefore();
      },
      immediate: true,
    }
  },
  methods: {

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },

    loadBloodGlucoseDateRecordListBefore: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.bloodGlucoseDateRecordList.length > 0 ? this.bloodGlucoseDateRecordList[this.bloodGlucoseDateRecordList.length - 1].date : null;

      this.$reqGet({
        path: '/mt/blood_glucose/date_record/list/get/before',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let bloodGlucoseDateRecordList = this.bloodGlucoseDateRecordList;
            this.$appendByOrder(bloodGlucoseDateRecordList, dateNodeList, "date", "DESC");
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })

    },
  }
}
</script>

<style scoped>

</style>