<template>
  <calendar
      :date-mark-definitions="dateMarkDefinitions"
      :date-marks="dateMarks"
      @changecurrent="handleCurrentMonthChange"
  >
  </calendar>
</template>

<script>
import Calendar from "@/components/calendar/Calendar";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import {BloodGlucoseLevel} from "@/assets/javascript/blood-glucose-utils";

export default {
  name: "BloodGlucoseCalendar",
  mixins: [httpapi],
  components: {Calendar},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      /**
       * 预定义血糖显示样式
       */
      dateMarkDefinitions: [
        /**
         * 血糖正常
         */
        {
          backgroundColor: '#faf4f5',
        },
        /**
         * 血糖偏低
         */
        {
          backgroundColor: '#d2e3f1',
        },
        /**
         * 血糖偏高
         */
        {
          backgroundColor: '#e3c7ee',
        }
      ],

      dateMarks: {},

      /**
       * 按月份保存血糖日记录列表
       */
      bloodGlucoseDateRecordListMonthMap: {},

      /**
       * 正在加载的月份
       */
      loadingMonthMap: {},
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.bloodGlucoseDateRecordListMonthMap = [];
        this.loadingMonthMap = [];
        this.loadCurrentMonthAround(new Date());
      }
    }
  },
  methods: {

    handleCurrentMonthChange: function (e) {
      let month = e.month;
      this.loadCurrentMonthAround(month);
    },

    notifyDateChange: function (date) {
      if (date) {
        this.loadBloodGlucoseDateRecordListByMonth(date);
      }
    },

    updateDateMarks: function () {
      let dateMarks = {};

      let bloodGlucoseDateRecordListMonthMap = this.bloodGlucoseDateRecordListMonthMap;
      for (let monthStart in bloodGlucoseDateRecordListMonthMap) {
        let bloodGlucoseDateRecordList = bloodGlucoseDateRecordListMonthMap[monthStart];
        for (let dateNode of bloodGlucoseDateRecordList) {
          let withBloodGlucoseLow = false;
          let withBloodGlucoseHigh = false;
          let withBloodGlucoseFine = false;
          for (let timeNode of dateNode.value.bloodGlucoseList) {
            switch (timeNode.value.level) {
              case BloodGlucoseLevel.LOW:
                withBloodGlucoseLow = true;
                break;
              case BloodGlucoseLevel.BAD:
              case BloodGlucoseLevel.WORSE:
                withBloodGlucoseHigh = true;
                break;
              case BloodGlucoseLevel.NORMAL:
              case BloodGlucoseLevel.FINE:
              case BloodGlucoseLevel.ORDINARY:
                withBloodGlucoseFine = true;
                break;
            }
          }
          let dateMarkIndex = null;
          if (withBloodGlucoseHigh) {
            dateMarkIndex = 2;
          } else if (withBloodGlucoseLow) {
            dateMarkIndex = 1;
          } else if (withBloodGlucoseFine) {
            dateMarkIndex = 0;
          }
          if (dateMarkIndex != null) {
            dateMarks[dateNode.date] = dateMarkIndex;
          }
        }
      }
      this.dateMarks = dateMarks;
    },

    loadCurrentMonthAround: function (month) {
      month = TimeUtils.format('yyyy-MM-dd', TimeUtils.getMonthStart(month));
      let preMonth = TimeUtils.format('yyyy-MM-dd', TimeUtils.plusMonths(month, -1));
      let postMonth = TimeUtils.format('yyyy-MM-dd', TimeUtils.plusMonths(month, 1));
      if (!this.bloodGlucoseDateRecordListMonthMap[preMonth]) {
        this.loadBloodGlucoseDateRecordListByMonth(preMonth);
      }
      this.loadBloodGlucoseDateRecordListByMonth(month);
      if (!this.bloodGlucoseDateRecordListMonthMap[postMonth]) {
        this.loadBloodGlucoseDateRecordListByMonth(postMonth);
      }
    },

    loadBloodGlucoseDateRecordListByMonth: function (dateInMonth) {
      if (!this.userId || !this.organizationId) return;
      let monthStart = TimeUtils.format('yyyy-MM-dd', TimeUtils.getMonthStart(dateInMonth));
      if (this.loadingMonthMap[monthStart]) return;
      this.loadingMonthMap[monthStart] = true;

      this.$reqGet({
        path: '/mt/blood_glucose/date_record/list/get/by_month',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          dateInMonth, monthStart
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let bloodGlucoseDateRecordList = res.data;
            this.bloodGlucoseDateRecordListMonthMap[monthStart] = bloodGlucoseDateRecordList;
            this.updateDateMarks();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            delete this.loadingMonthMap[monthStart];
          });
    }
  }
}
</script>

<style scoped>

</style>