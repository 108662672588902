<template>
  <div>
    <linear-chart
        class="chart"
        :serials="chartSerials"
        @scrolltoleft="handleScrollToLeft"
        @scrolltoright="handleScrollToRight"
    >
    </linear-chart>
    <div
        class="bloodglucose-type-container layout-horizontal margin-top padding-horizontal-large padding-bottom-large"
        style="width: max-content; overflow-x: scroll;">
      <div
          class="btn-bloodglucose-type"
          :class="{
            'active': !visibleBloodGlucoseType,
          }"
          @click="handleClickBloodGlucoseType(null)"
      >
        <div
            class="dot"
        ></div>
        全部
      </div>
      <div
          class="btn-bloodglucose-type"
          :class="{
            'active': visibleBloodGlucoseType === chartSerial.bloodGlucoseType
          }"
          v-for="chartSerial in allChartSerials"
          :key="chartSerial.key"
          @click="handleClickBloodGlucoseType(chartSerial.bloodGlucoseType)"
      >
        <div
            class="dot"
            :style="`background-color: ${chartSerial.color}`"
        ></div>
        {{ chartSerial.bloodGlucoseTypeStr }}
      </div>
    </div>
  </div>
</template>

<script>
import LinearChart from "@/components/chart/LinearChart";
import httpapi from "@/assets/javascript/httpapi";
import {BloodGlucoseType, BloodGlucoseUtils} from "@/assets/javascript/blood-glucose-utils";
import {BloodGlucoseColors} from "@/pages/minitools/blood_glucose/base/BloodGlucoseColors";
import {DateChartConverter} from "@/components/chart/DateChartConverter";
import ExactNumber from "@/assets/javascript/exact-number";
import {TimeUtils} from "@/assets/javascript/time-utils";

const colors = BloodGlucoseColors;

export default {
  name: "BloodGlucoseLinearChart",
  mixins: [httpapi],
  components: {LinearChart},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      /**
       * api同步参数
       */
      loadingCode: 1,
      busyLoadingCode: 0,

      /**
       * 血糖日记录列表
       */
      bloodGlucoseDateRecordList: [],

      /**
       * 图形参数
       */

      /**
       * 全部图形序列
       */
      allChartSerials: [],

      /**
       * 当前显示的图形序列
       */
      chartSerials: [],

      /**
       * 当前显示的图形序列key, null表示全部
       */
      visibleBloodGlucoseType: null,

      /**
       * 清除缓存
       */
      clearCaches: false,

      /**
       * 滑动到指定的未知
       */
      activeKey: null,

      serialDefinitions: {
        'pre_breakfast': {
          type: BloodGlucoseType.PRE_BREAKFAST,
          fieldName: 'preBreakfast',
          color: colors.preBreakfastColor,
        },
        'post_breakfast_1h': {
          type: BloodGlucoseType.POST_BREAKFAST_1H,
          fieldName: 'postBreakfast1h',
          color: colors.postBreakfast1hColor,
        },
        'post_breakfast_2h': {
          type: BloodGlucoseType.POST_BREAKFAST_2H,
          fieldName: 'postBreakfast2h',
          color: colors.postBreakfast2hColor,
        },
        'pre_forenoon': {
          type: BloodGlucoseType.PRE_FORENOON,
          fieldName: 'preForenoon',
          color: colors.preForenoonColor,
        },
        'post_forenoon_1h': {
          type: BloodGlucoseType.POST_FORENOON_1H,
          fieldName: 'postForenoon1h',
          color: colors.postForenoon1hColor,
        },
        'post_forenoon_2h': {
          type: BloodGlucoseType.POST_FORENOON_2H,
          fieldName: 'postForenoon2h',
          color: colors.postForenoon2hColor,
        },
        'pre_lunch': {
          type: BloodGlucoseType.PRE_LUNCH,
          fieldName: 'preLunch',
          color: colors.preLunchColor,
        },
        'post_lunch_1h': {
          type: BloodGlucoseType.POST_LUNCH_1H,
          fieldName: 'postLunch1h',
          color: colors.postLunch1hColor,
        },
        'post_lunch_2h': {
          type: BloodGlucoseType.POST_LUNCH_2H,
          fieldName: 'postLunch2h',
          color: colors.postLunch2hColor,
        },
        'pre_afternoon': {
          type: BloodGlucoseType.PRE_AFTERNOON,
          fieldName: 'preAfternoon',
          color: colors.preAfternoonColor,
        },
        'post_afternoon_1h': {
          type: BloodGlucoseType.POST_AFTERNOON_1H,
          fieldName: 'postAfternoon1h',
          color: colors.postAfternoon2hColor,
        },
        'post_afternoon_2h': {
          type: BloodGlucoseType.POST_AFTERNOON_2H,
          fieldName: 'postAfternoon2h',
          color: colors.postAfternoon2hColor,
        },
        'pre_supper': {
          type: BloodGlucoseType.PRE_SUPPER,
          fieldName: 'preSupper',
          color: colors.preSupperColor,
        },
        'post_supper_1h': {
          type: BloodGlucoseType.POST_SUPPER_1H,
          fieldName: 'postSupper1h',
          color: colors.postSupper1hColor,
        },
        'post_supper_2h': {
          type: BloodGlucoseType.POST_SUPPER_2H,
          fieldName: 'postSupper2h',
          color: colors.postSupper2hColor,
        },
        'pre_evening': {
          type: BloodGlucoseType.PRE_EVENING,
          fieldName: 'preEvening',
          color: colors.preEveningColor,
        },
        'post_evening_1h': {
          type: BloodGlucoseType.POST_EVENING_1H,
          fieldName: 'postEvening1h',
          color: colors.postEvening1hColor,
        },
        'post_evening_2h': {
          type: BloodGlucoseType.POST_EVENING_2H,
          fieldName: 'postEvening2h',
          color: colors.postEvening2hColor,
        },
        'bedtime': {
          type: BloodGlucoseType.BEDTIME,
          fieldName: 'bedtime',
          color: colors.bedtimeColor,
        },
        'before_dawn': {
          type: BloodGlucoseType.BEFORE_DAWN,
          fieldName: 'beforeDawn',
          color: colors.beforeDawnColor,
        }

      },
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.bloodGlucoseDateRecordList = [];
        this.loadBloodGlucoseDateRecordListBefore();
      },
      immediate: true,
    }
  },
  methods: {

    handleScrollToLeft: function () {
      this.loadBloodGlucoseDateRecordListBefore();
    },

    handleScrollToRight: function () {
      this.loadBloodGlucoseDateRecordListAfter();
    },

    handleClickBloodGlucoseType: function (type) {
      this.visibleBloodGlucoseType = type;
      this.updateVisibleChartSerials();
    },

    updateChartSerials: function () {
      let bloodGlucoseDateRecordList = this.bloodGlucoseDateRecordList;
      let serialDefinitions = this.serialDefinitions;

      let tempChartSerials = [];

      for (let serialKey in serialDefinitions) {
        let serialDefinition = serialDefinitions[serialKey];
        tempChartSerials.push({
          definition: serialDefinition,
          color: serialDefinition.color,
          key: serialKey,
          values: [],
          converter: new DateChartConverter(0),
        });
      }

      for (let dateNode of bloodGlucoseDateRecordList) {
        for (let tempChartSerial of tempChartSerials) {
          let timeNode = dateNode.value[tempChartSerial.definition.fieldName];
          if (timeNode) {
            tempChartSerial.values.push({
              key: dateNode.date,
              val: ExactNumber.toFloat(timeNode.value.glucose),
              valLabel: `${ExactNumber.stringify(timeNode.value.glucose)}mmol/L ${BloodGlucoseUtils.getBloodGlucoseLevelStr(timeNode.value.level)} ${BloodGlucoseUtils.getBloodGlucoseTypeStr(tempChartSerial.definition.type)}`
            })
          }
        }
      }

      let allChartSerials = [];
      for (let tempChartSerial of tempChartSerials) {
        if (tempChartSerial.values.length > 0) {
          tempChartSerial.bloodGlucoseType = tempChartSerial.definition.type;
          tempChartSerial.bloodGlucoseTypeStr = BloodGlucoseUtils.getBloodGlucoseTypeStr(tempChartSerial.definition.type);

          delete tempChartSerial.definition;
          allChartSerials.push(tempChartSerial);
        }
      }

      this.allChartSerials = allChartSerials;

      this.updateVisibleChartSerials();

    },

    updateVisibleChartSerials: function () {
      let chartSerials = [];

      let visibleBloodGlucoseType = this.visibleBloodGlucoseType;
      for (let chartSerial of this.allChartSerials) {
        if (visibleBloodGlucoseType == null || visibleBloodGlucoseType === chartSerial.bloodGlucoseType) {
          chartSerials.push(chartSerial);
        }
      }

      this.chartSerials = chartSerials;
    },

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },

    loadBloodGlucoseDateRecordListBefore: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.bloodGlucoseDateRecordList.length > 0 ? this.bloodGlucoseDateRecordList[0].date : null;

      this.$reqGet({
        path: '/mt/blood_glucose/date_record/list/get/before',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let bloodGlucoseDateRecordList = this.bloodGlucoseDateRecordList;
            this.$appendByOrder(bloodGlucoseDateRecordList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadBloodGlucoseDateRecordListAfter: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.bloodGlucoseDateRecordList.length > 0 ? this.bloodGlucoseDateRecordList[this.bloodGlucoseDateRecordList.length - 1].date : null;

      this.$reqGet({
        path: '/mt/blood_glucose/date_record/list/get/after',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let bloodGlucoseDateRecordList = this.bloodGlucoseDateRecordList;
            this.$appendByOrder(bloodGlucoseDateRecordList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });

    },

    loadBloodGlucoseDateRecordListAround: function (date) {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      date = TimeUtils.format('yyyy-MM-dd', date);

      this.$reqGet({
        path: '/mt/blood_glucose/date_record/list/get/around',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let bloodGlucoseDateRecordList = this.bloodGlucoseDateRecordList;
            this.$appendByOrder(bloodGlucoseDateRecordList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadBloodGlucoseDateRecord: function (date) {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      date = TimeUtils.format('yyyy-MM-dd', date);

      this.$reqGet({
        path: '/mt/blood_glucose/date_record/get',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNode = res.data;
            let bloodGlucoseDateRecordList = this.data.bloodGlucoseDateRecordList;
            if (dateNode) {
              this.$appendByOrder(bloodGlucoseDateRecordList, [dateNode], "date", "ASC");
            } else {
              for (let n = bloodGlucoseDateRecordList.length - 1; n >= 0; n--) {
                if (bloodGlucoseDateRecordList[n].date === date) {
                  bloodGlucoseDateRecordList.splice(n, 1);
                  break;
                }
              }
            }
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });

    },


  }
}
</script>

<style scoped>

.chart {
  height: 200px;
}

.bloodglucose-type-container {
  overflow-x: scroll;
  width: max-content;
}

.bloodglucose-type-container::-webkit-scrollbar {
  visibility: hidden;
  height: 0px;
}

.btn-bloodglucose-type {
  background-color: #faf4f5;
  margin-right: 8px;
  border-radius: 100px;
  padding: 8px 16px;
  user-select: none;
  cursor: pointer;
  color: #bec0c0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-bloodglucose-type.active {
  background-color: #f091a6;
  color: white;
}

.btn-bloodglucose-type:hover {
  opacity: .7;
}

.btn-bloodglucose-type>.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: white;
  border: 2px solid white;
  border-radius: 50%;
  margin-right: 4px;
}

</style>